import React from "react";
import styled from 'styled-components'
import Web3Status from '../Web3Status'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { RowFixed } from '../Row'
import { BASE_DECIMALS, BASE_TICKER } from "../../constants";
import { supportedChain } from '../../utils'
import { getDisplayBalance } from '../../utils/formatBalance'
import useBalance from '../../hooks/useBalance'
import Logo from '../../assets/images/logo.png'
import { NavLink } from "react-router-dom";

const HeaderFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 120px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  top: 0;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 2;
  height: 5em;  
  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size:0.875em;
  height: 3em;  
`};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;


`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
   flex-direction: row-reverse;
    align-items: center;
  `};
`

const HeaderRow = styled(RowFixed)`
  width: fit-content;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   width: 100%;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${({ theme, active }) => (!active ? theme.bg1 : theme.bg3)};
  border-radius: 12px;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;

  :focus {
    border: 1px solid blue;
  }
  /* :hover {
    background-color: ${({ theme, active }) => (!active ? theme.bg2 : theme.bg4)};
  } */
`
const TitleText = styled.div`
  font-size: 2em;
  white-space: nowrap;
  width:fit-content;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `};
`

const LogoWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1em;
  align-items: center;
  padding-right: 2em;
  user-select: none;
`

const BalanceText = styled.div`
  font-weight: 500;
  padding: 0 0.5rem 0 0.75rem;
  flex-shrink:0;
  color: ${({ theme }) => theme.text2};
`

const LogoImage = styled.img`
  width: 64px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  width: 32px;
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  display: none;
  `};
`;

const MenuItems = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 2em;
  align-items: center;
  padding-left: 1em;

 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    font-size: 0.75em;
 `};
`;

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  width: fit-content;

  &.${activeClassName} {
    font-weight: 600;
    color: ${({ theme }) => theme.primary1};
  }

  :hover,
  :focus {
    color: ${({ theme }) => theme.primary1};
  }
`


 export default function Header() {
    const { account, chainId } = useWeb3React<Web3Provider>();
    const balance = useBalance();
    return (
      <HeaderFrame>
        <HeaderRow>
        <LogoWrapper>
              <LogoImage src={Logo} alt="logo" />
            <TitleText>
              upCRO
            </TitleText>
          </LogoWrapper>
          <MenuItems>
            <StyledNavLink id={`stake-nav-link`} exact={true} to={'/'}>
              CLAIM
            </StyledNavLink>
            <StyledNavLink id={`stake-nav-link`} exact={true} to={'/stake'}>
              STAKE
            </StyledNavLink>
          </MenuItems>
        </HeaderRow>
        <HeaderControls>
        
          <HeaderElement>        
          <AccountElement active={!!account} style={{ pointerEvents: 'auto' }}>
              {chainId && supportedChain(chainId!) && account && balance && (
                <BalanceText>
                  {getDisplayBalance(balance!, 2, BASE_DECIMALS)} {BASE_TICKER}
                </BalanceText>
              )}
              <Web3Status />
            </AccountElement>
          </HeaderElement>
        </HeaderControls>
      </HeaderFrame>
    )
  }
