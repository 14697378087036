import React from 'react';
import styled from 'styled-components';
import { TransactionResponse } from "@ethersproject/providers";
import Web3ReactManager from './components/Web3ReactManager';
//import { MarketGenerationEvent } from './pages/MarketGenerationEvent';
import { Switch, Route, HashRouter } from 'react-router-dom';
import { useToast } from './components/ToastProvider';
//import { Footer } from './components/Footer';
import { IconType } from './components/Icon/IconType';
import TransactionContext from './contexts/TransactionContext';
//import { Overview } from './pages/Overview';
//import { MarketGenerationEvent } from './pages/MarketGenerationEvent';
import Header from './components/Header';
//import { ClaimRewards } from './pages/ClaimRewards';
//import { Staking } from './pages/Staking';
//import { ComingSoon } from './pages/ComingSoon';
import { Footer } from './components/Footer';
//import { Staking } from './pages/Staking';
//import { ComingSoon } from './pages/ComingSoon';
//import { useWeb3React } from '@web3-react/core';
//import { MarketDistributionService } from './services/MarketDistributionService';
// import { MarketGenerationService } from './services/MarketGenerationService';
//import { supportedChain } from './utils';
import { AppContextProvider } from './contexts/AppContext';
import { Staking } from './pages/Staking';
import { ClaimRewards } from './pages/ClaimRewards';

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`
const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
 
`
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  min-height: calc(100vh - 11.5em);

  ${({ theme }) => theme.mediaWidth.upToMedium`
    min-height: calc(100vh - 10em);
  `};

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    min-height: calc(100vh - 8em);
`};
`

export const App = () => {
  const { addToast } = useToast();
  //const { account, library, chainId } = useWeb3React()
  //const [generationActive, setGenerationActive] = useState<boolean>(false)
  //const [distributionComplete, setDistributionComplete] = useState<boolean>(false) 

  const addPendingTransaction = (description: string, txResponse: TransactionResponse) => {
    txResponse.wait().then(txReceipt => {
      addToast(
        txReceipt.status === 1 ? IconType.Success : IconType.Failure,
        `${description} ${txReceipt.status === 1 ? "Completed" : "Failed"}`,
        txReceipt.transactionHash)
    })
  }
  
//   const getContributionStatus = async () => {
//     if (library && account && chainId && supportedChain(chainId)) {
//         const service = new MarketGenerationService(library, account!);        
//         setGenerationActive(await service.isActive());
//     }
// }

//   const getDistributionStatus = async () => {
//     if (library && account && chainId && supportedChain(chainId)) {
//         const service = new MarketDistributionService(library, account!);        
//         setDistributionComplete(await service.distributionComplete());
//     }
// }


// useEffect(() => {
//   getDistributionStatus()
//     const timer = setInterval(() => getDistributionStatus(), 30000)
//     return () => clearInterval(timer)
// }, [library, account, chainId])
 

  return (
    <HashRouter>
      <AppWrapper>
        <TransactionContext.Provider value={{ addPendingTransaction }}>
        <AppContextProvider>
          <HeaderWrapper>
            <Header />
          </HeaderWrapper>
          <BodyWrapper>
            <Web3ReactManager>
            <Switch> 
              {/* <Route exact strict path="/" component={MarketGenerationEvent} />
              <Route exact strict path="/:ref" component={MarketGenerationEvent} /> */}
                {/* <Route path="/" component={ClaimRewards} />     */}
               {/* <Route path="/claim" component={ClaimRewards} />
                <Route path="/" component={Staking} /> */}
                 {/* {distributionComplete 
                 ? <Route path="/" component={ClaimRewards} />
                 :
                  <>
                    <Route exact strict path="/" component={MarketGenerationEvent} />
                    <Route exact strict path="/:ref" component={MarketGenerationEvent} />
                  </>
                 } */}
                {/*generationActive ?                  
                  <>                   
                    <Route exact strict path="/" component={MarketGenerationEvent} />
                    <Route exact strict path="/:ref" component={MarketGenerationEvent} />
                  </>
                :*/}
                  <Route exact path="/" component={ClaimRewards} />
                  {/* <Route exact strict path="/:ref" component={MarketGenerationEvent} /> */}
                  <Route exact strict path="/stake" component={Staking} />
                
              </Switch>
             
            </Web3ReactManager>
          </BodyWrapper>
          <Footer />
          </AppContextProvider>
        </TransactionContext.Provider>
      </AppWrapper>
    </HashRouter>
  );
}

export default App;
