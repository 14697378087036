import React, { useContext, useEffect, useState } from "react"
import styled, { ThemeContext } from "styled-components"
import { PrimaryCard } from "../../components/Card"
import { ButtonPrimary, Option, PendingContent } from "../../components/Button";
import useTokenBalance from "../../hooks/useTokenBalance";
import { POOL_ADDRESS, ROOTED_ADDRESS, ROOTED_TICKER, STAKING_ADDRESS, STAKING_TICKER } from "../../constants";
import BigNumber from "bignumber.js";
import CurrencyInput from "../../components/CurrencyInput";
import { getBalanceNumber, getDisplayBalance, getFullDisplayBalance } from "../../utils/formatBalance";
import { useWeb3React } from "@web3-react/core";
import { TokenService } from "../../services/TokenService";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import { StakingService } from "../../services/StakingService";
import TransactionCompletedModal from "../../components/TransactionCompletedModal";
import { ErrorMessage } from "../../components/ErrorMessage";
import { getEtherscanLink, supportedChain } from "../../utils";
import WalletModal from "../../components/WalletModal";
import Copy from "../../components/AccountDetails/Copy";
import { ExternalLink } from "../../components/Link";
import { VaultStaking } from "../../components/VaultStaking";

const PageWrapper = styled.div`
    padding-top:2em;
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: auto auto;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns: auto;
 `};

`

const CardContent = styled.div`
    display: grid;
    grid-gap: 1.5em;
    padding-bottom: 0.5em;
    width: 26em;

 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    font-size: 0.75em;   
 `};
`

const Title = styled.div`
    padding: 0.5em 0;
    font-size: 2em;
    letter-spacing:0.025em;
    text-align: center;
`

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5em;
    align-items: center;
`

const TabsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0.15em;
    border-radius: 0.5em;
    background-color: ${({ theme }) => theme.bg2};
`

const Rate = styled.div`
    padding: 0.25em;
    font-size: 0.875rem;
    justify-self: end;
    color: ${({ theme }) => theme.text3};
`

const ConnectWalletWrapper = styled.div`
    padding-top: 2em;
`

const AddressLink = styled(ExternalLink)`
    font-family: monospace;
    color: ${({ theme }) => theme.text3};
    display: flex;
    :hover {
        color: ${({ theme }) => theme.text2};
    } 
`

const AddressWrapper = styled.div`
    color: ${({ theme }) => theme.text3};
    font-size: 0.825rem;
    display:grid;
    grid-gap: 0.5em;
    grid-template-columns: auto 1fr auto;
    align-items:center;
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        width: 100%;
        font-size: 0.575rem;
        grid-gap: 0.25em;
    `};
`

enum Action {
    Stake,
    Unstake
}

enum StakingStatus {
    None,
    Approving,
    Approved,
    Staking,
    Staked
}

export const Staking = () => {
    const { account, library, chainId } = useWeb3React();
    const [action, setAction] = useState<Action>(Action.Stake);
   
    const rootedBalance = useTokenBalance(ROOTED_ADDRESS);
    const stakingBalance = useTokenBalance(STAKING_ADDRESS);
    const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
    const [value, setValue] = useState<string>("");
    const [status, setStatus] = useState<StakingStatus>(StakingStatus.None);
    const [transactionHash, setTransactionHash] = useState<string>("");
    const [error, setError] = useState("");
    const [completedAction, setCompletedAction] = useState("");
    const [pendingAction, setPendingAction] = useState("");
    const [rate, setRate] = useState("");
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const toggleWalletModal = () => { setModalOpen(!modalOpen) }
    const theme = useContext(ThemeContext);

    useEffect(() => {
        setBalance(action === Action.Stake ? rootedBalance : stakingBalance)
    }, [action, rootedBalance, stakingBalance])

    
    useEffect(() => {
        const getRate = async () => {
            if (account && chainId && supportedChain(chainId!)) {
                const stakedPerRooted = await new StakingService(library, account!).getRate();
                setRate(`${action === Action.Stake ? `1 ${ROOTED_TICKER} = ${stakedPerRooted.toFixed(4)} ${STAKING_TICKER}` : `1 ${STAKING_TICKER} = ${(1/stakedPerRooted).toFixed(4)} ${ROOTED_TICKER}`}`);
            }
        }

        getRate();    
        const timer = setInterval(() => getRate(), 30000)
        return () => clearInterval(timer)
    }, [library, account, chainId, action])

    useEffect(() => {
        const getIsApprove = async () => {
            const service = new TokenService(library, account!, ROOTED_ADDRESS);
            const approved = await service.isApproved(STAKING_ADDRESS);
            setIsApproved(approved);
            if(approved) {
                setStatus(StakingStatus.Approved);
            }
        }
        if(account && chainId && supportedChain(chainId!)) {
            getIsApprove();
        }
    }, [library, account, chainId])

    const approve = async () => {
        try {
            setStatus(StakingStatus.Approving);
            const service = new TokenService(library, account!, ROOTED_ADDRESS);
            const txResponse = await service.approve(STAKING_ADDRESS);
            if (txResponse) {
                const receipt = await txResponse.wait()
                if (receipt?.status === 1) {
                    setTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Transaction Failed")
                }
            }
            setStatus(StakingStatus.Approved);
            setIsApproved(true);
        }
        catch (e) {
            console.log(e);
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            setStatus(StakingStatus.None);
        }
    }

    const stake = async () => {
        const amount = parseFloat(value);
        if (Number.isNaN(amount) || amount <= 0) {
            setError("Enter amount");
            return;
        }
        setError("");

        try {
            setCompletedAction(`${value} ${action === Action.Stake ? ROOTED_TICKER : STAKING_TICKER} ${action === Action.Stake ? "staked" : "unstaked"}`);
            setPendingAction(`${action === Action.Stake ? "Staking" : "Unstaking"}...`);
            setStatus(StakingStatus.Staking);

            const service = new StakingService(library, account!)
            const txResponse = action === Action.Stake 
                ? await service.stake(value) 
                : await service.unstake(value)

            if (txResponse) {
                const receipt = await txResponse.wait()
                if (receipt?.status === 1) {
                    setTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Transaction Failed")
                }
            }
            setStatus(StakingStatus.Staked);
            setValue("");
        }
        catch (e) {
            console.log(e)
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            setStatus(StakingStatus.None)
        }
    }
    
    return (
        <PageWrapper>
            <TransactionCompletedModal title={completedAction} hash={transactionHash} isOpen={status === StakingStatus.Staked} onDismiss={() => setStatus(StakingStatus.None)} />
            <PrimaryCard width="auto">
            
                <CardContent>
                    <Title>Staking</Title>
                    {account
                        ?
                        <>
                    <TabsWrapper>
                        <Option padding="1em" onClick={() => setAction(Action.Stake)} active={action === Action.Stake}>Stake {ROOTED_TICKER}</Option>
                        <Option padding="1em" onClick={() => setAction(Action.Unstake)} active={action === Action.Unstake}>Unstake {STAKING_TICKER}</Option>
                    </TabsWrapper>
                    <Rate>{rate}</Rate>
                    <CurrencyInput
                        value={value}
                        balance={getDisplayBalance(balance, 2)}
                        numericBalance={getBalanceNumber(balance)}
                        onSubmit={stake}
                        ticker={action === Action.Stake ? ROOTED_TICKER : STAKING_TICKER}
                        label={`Amount to ${action === Action.Stake ? "stake" : "unstake"}`}
                        onMax={() => setValue(getFullDisplayBalance(balance))}
                        showMaxButton={true}
                        onUserInput={setValue}
                        id={"stakingInput"}
                    />
                    {action === Action.Unstake || isApproved
                        ? 
                            <ButtonPrimary disabled={status === StakingStatus.Staking || !supportedChain(chainId)} onClick={stake}>
                                {status === StakingStatus.Staking 
                                ? <PendingContent text={pendingAction}/> 
                                : `${action === Action.Stake ? "Stake" : "Unstake"}`}
                            </ButtonPrimary>
                        :
                            <ButtonsWrapper>
                                <ButtonPrimary onClick={approve} disabled={status === StakingStatus.Approving || !supportedChain(chainId)}>
                                    {status === StakingStatus.Approving 
                                        ? <PendingContent text={"Approving..."}/>
                                        : status === StakingStatus.Approved ? "Approved" : "Approve"
                                    }
                                </ButtonPrimary>
                                <ButtonPrimary disabled={status !== StakingStatus.Approved || !supportedChain(chainId)} onClick={stake}>
                                    {status === StakingStatus.Staking
                                        ? <PendingContent text={"Staking..."}/>
                                        : "Stake"
                                    }
                                </ButtonPrimary>
                                
                            </ButtonsWrapper>
                    }

                    <ButtonsWrapper>
                        <ButtonPrimary onClick={() => window.open(`https://cro.empiredex.org/#/swap?inputCurrency=${ROOTED_ADDRESS}`, "_blank")?.focus()}>Empiredex</ButtonPrimary>
                        <ButtonPrimary onClick={() => window.open(`https://dexscreener.com/cronos/${POOL_ADDRESS}`, "_blank")?.focus()}>Dexscreener</ButtonPrimary>
                    </ButtonsWrapper>
                    <AddressWrapper>
                        <span>upCRO</span>
                        <AddressLink href={getEtherscanLink(chainId!, ROOTED_ADDRESS, 'address')}>
                        <span style={{ marginLeft: '4px' }}>{ROOTED_ADDRESS}</span>
                        </AddressLink>
                            <Copy toCopy={ROOTED_ADDRESS} color={theme.text3}/>
                   
                        <span>xUpCRO</span>
                        <AddressLink href={getEtherscanLink(chainId!, STAKING_ADDRESS, 'address')}>
                        <span style={{ marginLeft: '4px' }}>{STAKING_ADDRESS}</span>
                        </AddressLink>
                            <Copy toCopy={STAKING_ADDRESS} color={theme.text3}/>
                    </AddressWrapper>  

                    {error ? <ErrorMessage error={error} /> : null}
                    </>
                        :
                        <>
                        <WalletModal walletModalOpen={modalOpen} toggleWalletModal={toggleWalletModal} />
                        <ConnectWalletWrapper>
                            <ButtonPrimary onClick={toggleWalletModal}>Connect to a wallet</ButtonPrimary>
                        </ConnectWalletWrapper>
                        </>}
                </CardContent>
            </PrimaryCard>
            
            <VaultStaking/>
        </PageWrapper>
    )
}