import React, { useContext, useEffect, useState } from "react"
import styled, { ThemeContext } from "styled-components"
import { PrimaryCard } from "../../components/Card"
import { ButtonPrimary } from "../../components/Button";
import { useWeb3React } from "@web3-react/core";
import { MarketGenerationService } from "../../services/MarketGenerationService";
import ContentLoader from "react-content-loader";
import { Claim } from "../../components/Claim";
import WalletModal from '../../components/WalletModal'
import { MarketDistributionService } from "../../services/MarketDistributionService";
import { BASE_TICKER, POOL_ADDRESS, ROOTED_ADDRESS, ROOTED_TICKER } from "../../constants";
import { formatNumber } from "../../utils/format";
import { ExternalLink } from "../../components/Link";
import { getEtherscanLink, supportedChain } from "../../utils";
import Copy from "../../components/AccountDetails/Copy";
import { GroupMarketGenerationService } from "../../services/GroupMarketGenerationService";

const PageWrapper = styled.div`
    padding-top:2em;
`

const CardContent = styled.div`
    display: grid;
    grid-gap: 1.5em;
    padding-bottom: 0.5em;
    width: 26em;
    justify-items: center;


 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    font-size: 0.75em;   
 `};
`

const Title = styled.div`
    font-family: Arial;
    padding-top: 0.5em;
    font-size: 2em;
    letter-spacing:0.025em;
    text-align: center;
`

const Subtitle = styled.div`
    padding: 0.25em;
    font-size: 0.875em;
    text-align: center;
`

const Contribution = styled.div`
    font-size: 1.25em;
    text-align: center;
`


const ContributionInfo = styled.div`
    display: grid;
    grid-gap: 0.5em;
`

const ConnectWalletWrapper = styled.div`
    padding-top: 2em;
`

const TextWrapper = styled.div`
    padding: 0.5em 0;
    justify-content: center;
    justify-self:center;
    dispay:grid;
    grid-template-columns: auto auto;
    color: ${({ theme }) => theme.text3};
`

const ButtonsWrapper = styled.div`
    display:grid;
    grid-gap: 1.25em;
    grid-template-columns: 1fr 1fr;
    width:100%;
`

const AddressLink = styled(ExternalLink)`
    font-family: monospace;
    color: ${({ theme }) => theme.text3};
    display: flex;
    :hover {
        color: ${({ theme }) => theme.text2};
    } 
`

const AddressWrapper = styled.div`
    color: ${({ theme }) => theme.text3};
    font-size: 0.825rem;
    display:grid;
    grid-gap: 0.5em;
    grid-template-columns: auto 1fr auto;
    align-items:center;
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        width: 100%;
        font-size: 0.575rem;
        grid-gap: 0.25em;
    `};
`

const ContributionLoader = () => (
    <ContentLoader
        width={100}
        height={18}
        speed={1}
        animate={true}
        backgroundColor="#f6f6ef"
        foregroundColor="#e8e8e3"
        backgroundOpacity={0.06}
        foregroundOpacity={0.12}
    >
        <rect x="0" y="0" width="100" height="18" />
    </ContentLoader>)

export const ClaimRewards = () => {
    const { account, library, chainId } = useWeb3React()
    const [contribution, setContribution] = useState<string>("")
    const [contributionLoading, setContributionLoading] = useState<boolean>(false)
    
    const [totalContribution, setTotalContribution] = useState<string>("")
    const [totalContributionLoading, setTotalContributionLoading] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    
    const [availableToClaimLoading, setAvailableToClaimLoading] = useState<boolean>(false)
    const [availableToClaim, setAvailableToClaim] = useState<number>(0);
    const [remainingToClaim, setRemainingToClaim] = useState<number>(0);

    const [availableToClaimBsc, setAvailableToClaimBsc] = useState<number>(0);
    const [remainingToClaimBsc, setRemainingToClaimBsc] = useState<number>(0); 
    
    const [referralRewardsToClaimLoading, setReferralRewardsToClaimLoading] = useState<boolean>(false)
    const [referralRewardsToClaim, setReferralRewardsToClaim] = useState<number>(0);
    const toggleWalletModal = () => { setModalOpen(!modalOpen) }
    const theme = useContext(ThemeContext);
  
    const getContributionStatus = async () => {
        if (library && account && supportedChain(chainId)) {

            const service = new MarketGenerationService(library, account!);
            const groupService = new GroupMarketGenerationService(library, account!);
            setContributionLoading(true);
            const croContribution = await service.getContribution(account);
            const bscContribution = await groupService.getContribution(account);
            setContribution((croContribution + bscContribution).toFixed(2));
            setContributionLoading(false);

            setTotalContributionLoading(true);
            setTotalContribution(await service.getTotalContribution());
            setTotalContributionLoading(false);
        }
    }

    const getClaimStatus = async () => {
        if (library && account && chainId) {
            const distributionService = new MarketDistributionService(library, account!);
            const groupService = new GroupMarketGenerationService(library, account!);
            setAvailableToClaimLoading(true);
            const croAvailableToClaim = await distributionService.getClaim();
            const bscAvailableToClaim = await groupService.getClaim();
            setAvailableToClaim(croAvailableToClaim.available);
            setRemainingToClaim(croAvailableToClaim.remaining);
            setAvailableToClaimBsc(bscAvailableToClaim.available);
            setRemainingToClaimBsc(bscAvailableToClaim.remaining);
            setAvailableToClaimLoading(false);

            setReferralRewardsToClaimLoading(true)
            setReferralRewardsToClaim(await distributionService.availableReferralRewardsToClaim());
            setReferralRewardsToClaimLoading(false)
        }
    }

    useEffect(() => {
        getContributionStatus()
    }, [library, account, chainId])

    useEffect(() => {
        getClaimStatus()
        const timer = setInterval(() => getClaimStatus(), 30000)
        return () => clearInterval(timer)
    }, [library, account, chainId])


    return (
        <PageWrapper>
            <PrimaryCard width="auto">
                <CardContent>
                    <Title>Market Generation Event</Title>
                    {account
                        ?
                        <>
                            <ContributionInfo>
                                <TextWrapper>
                                    {totalContributionLoading ? <ContributionLoader /> : <Contribution>{totalContribution} {BASE_TICKER}</Contribution>}
                                    <Subtitle>Total contribution</Subtitle>
                                </TextWrapper>
                                <TextWrapper>
                                    {contributionLoading ? <ContributionLoader /> : <Contribution>{contribution} {BASE_TICKER}</Contribution>}
                                    <Subtitle>My contribution</Subtitle>
                                </TextWrapper>
                                <TextWrapper>
                                    {availableToClaimLoading ? <ContributionLoader /> : <Contribution>{formatNumber(availableToClaim)} {ROOTED_TICKER}</Contribution>}
                                    <Subtitle>Available to claim</Subtitle>
                                </TextWrapper>
                                <TextWrapper>
                                    {availableToClaimLoading ? <ContributionLoader /> : <Contribution>{formatNumber(remainingToClaim)} {ROOTED_TICKER}</Contribution>}
                                    <Subtitle>Remaining to claim</Subtitle>
                                </TextWrapper>
                                <TextWrapper>
                                    {availableToClaimLoading ? <ContributionLoader /> : <Contribution>{formatNumber(availableToClaimBsc)} {ROOTED_TICKER}</Contribution>}
                                    <Subtitle>Available to claim for BSC contribution</Subtitle>
                                </TextWrapper>
                                <TextWrapper>
                                    {availableToClaimLoading ? <ContributionLoader /> : <Contribution>{formatNumber(remainingToClaimBsc)} {ROOTED_TICKER}</Contribution>}
                                    <Subtitle>Remaining to claim for BSC contribution </Subtitle>
                                </TextWrapper>
                                <TextWrapper>
                                    {referralRewardsToClaimLoading ? <ContributionLoader /> : <Contribution>{formatNumber(referralRewardsToClaim)} {ROOTED_TICKER}</Contribution>}
                                    <Subtitle>Referral rewards to claim</Subtitle>
                                </TextWrapper>
                            </ContributionInfo>
                            <Claim onClaim={getClaimStatus} />
                            <ButtonsWrapper>
                                <ButtonPrimary onClick={() => window.open(`https://cro.empiredex.org/#/swap?inputCurrency=${ROOTED_ADDRESS}`, "_blank")?.focus()}>Empiredex</ButtonPrimary>
                                <ButtonPrimary onClick={() => window.open(`https://dexscreener.com/cronos/${POOL_ADDRESS}`, "_blank")?.focus()}>Dexscreener</ButtonPrimary>
                            </ButtonsWrapper>
                            <AddressWrapper>
                                <span>upCRO</span>
                                <AddressLink href={getEtherscanLink(chainId!, ROOTED_ADDRESS, 'address')}>
                                    <span style={{ marginLeft: '4px' }}>{ROOTED_ADDRESS}</span>
                                </AddressLink>
                                <Copy toCopy={ROOTED_ADDRESS} color={theme.text3}/>
                            </AddressWrapper>     
                        </>
                        :
                        <>
                            <WalletModal walletModalOpen={modalOpen} toggleWalletModal={toggleWalletModal} />
                            <ConnectWalletWrapper>
                                <ButtonPrimary onClick={toggleWalletModal}>Connect to a wallet</ButtonPrimary>
                            </ConnectWalletWrapper>
                        </>}
                </CardContent>
            </PrimaryCard>
        </PageWrapper>
    )
}