import { Contract } from '@ethersproject/contracts'
import groupMarketGenerationAbi from '../constants/abis/groupMarketGeneration.json'
import claimCalculatorAbi from '../constants/abis/claimCalculator.json'
import { Web3Provider } from '@ethersproject/providers'
import { CLAIM_CALCULATOR_ADDRESS, GROUP_MARKET_GENERATION_ADDRESS } from '../constants'
import { getBalanceNumber } from '../utils/formatBalance';
import BigNumber from 'bignumber.js';
import { ClaimInfo } from '../dtos/ClaimInfo'

export class GroupMarketGenerationService {
    private contract: Contract;
    private claimCalculatorContract: Contract;
    private signer: any;
    private account: string;

    constructor(library: Web3Provider, account: string) {
        this.account = account;
        this.signer = library.getSigner(account).connectUnchecked()
        this.claimCalculatorContract = new Contract(CLAIM_CALCULATOR_ADDRESS, claimCalculatorAbi, this.signer);
        this.contract = new Contract(GROUP_MARKET_GENERATION_ADDRESS, groupMarketGenerationAbi, this.signer);
    }

    public async distributionComplete() {
        return await this.contract.distributionComplete();
    }

    public async getContribution(account: string) {
        const contribution = await this.contract.contribution(account);
        return getBalanceNumber(new BigNumber(contribution.toString()))
    }

    public async getClaim() {
        const claim = await this.claimCalculatorContract.getGroupMarketGenerationClaim(this.account);
        return new ClaimInfo(getBalanceNumber(new BigNumber(claim[0].toString())), getBalanceNumber(new BigNumber(claim[1].toString())));
    }

    public async claim() {
        return await this.contract.claim()
    }

    public async groupClaim() {
        return await this.contract.claimForGroup()
    }
}

