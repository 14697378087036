import { Contract } from '@ethersproject/contracts'
import marketDistributionAbi from '../constants/abis/marketDistribution.json'
import claimCalculatorAbi from '../constants/abis/claimCalculator.json'
import { Web3Provider } from '@ethersproject/providers'
import { CLAIM_CALCULATOR_ADDRESS, MARKET_DISTRIBUTION_ADDRESS } from '../constants'
import { getBalanceNumber } from '../utils/formatBalance';
import BigNumber from 'bignumber.js';
import { ClaimInfo } from '../dtos/ClaimInfo'

export class MarketDistributionService {
    private contract: Contract;
    private claimCalculatorContract: Contract;
    private signer: any;
    private account: string;

    constructor(library: Web3Provider, account: string) {
        this.account = account;
        this.signer = library.getSigner(account).connectUnchecked();
        this.claimCalculatorContract = new Contract(CLAIM_CALCULATOR_ADDRESS, claimCalculatorAbi, this.signer);
        this.contract = new Contract(MARKET_DISTRIBUTION_ADDRESS, marketDistributionAbi, this.signer);
    }

    public async distributionComplete() {
        return await this.contract.distributionComplete();
    }

    public async getClaim() {
        const claim = await this.claimCalculatorContract.getMarketDistributionClaim(this.account);        
        return new ClaimInfo(getBalanceNumber(new BigNumber(claim[0].toString())), getBalanceNumber(new BigNumber(claim[1].toString())));
    }

    public async availableReferralRewardsToClaim() {
        const referralRewards = new BigNumber((await this.contract.getReferralClaim(this.account)).toString());
        return getBalanceNumber(referralRewards);
    }
}

