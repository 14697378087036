import React from "react"
import styled from "styled-components"
import { Twitter, Github, Telegram, Medium, Discord } from "../Icon/social"
import { ExternalLink } from "../Link"

const FooterFrame = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  bottom: 0;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  height: 5.25em;
  grid-gap: 1em;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  height: 5em;  
`};
`

const LinksWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.5em;
  align-items: center;
  justify-items: center;  
`

const TextWrapper = styled.div`
 text-align: center;
 font-size: 0.875em;  
 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
  font-size: 0.8em;  
`};
`

export const Footer = () => {
  return (
  <FooterFrame>
      <LinksWrapper>
        <ExternalLink href="https://github.com/RootkitFinance/upcro-contracts"><Github/></ExternalLink>
        <ExternalLink href="https://twitter.com/rootkitfinance"><Twitter/></ExternalLink>
        <ExternalLink href="https://t.me/rootkitfinance"><Telegram /></ExternalLink>
        <ExternalLink href="https://rootkitfinance.medium.com/"><Medium /></ExternalLink>
        <ExternalLink href="https://discord.com/invite/9JvatK5u"><Discord /></ExternalLink>
      </LinksWrapper>
      <TextWrapper><ExternalLink href="https://rootkit.finance/">rootkit.finance</ExternalLink> project</TextWrapper>
  </FooterFrame>)
}