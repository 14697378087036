import { Contract } from '@ethersproject/contracts'
import marketGenerationAbi from '../constants/abis/marketGeneration.json'
import { Web3Provider } from '@ethersproject/providers'
import { BASE_DECIMALS, MARKET_GENERATION_ADDRESS } from '../constants'
import { AddressZero } from '@ethersproject/constants'
import { getBalanceNumber, getDisplayBalance } from '../utils/formatBalance'
import { parseEther } from '@ethersproject/units'
import BigNumber from 'bignumber.js'

export class MarketGenerationService {
    private contract: Contract
    private signer: any

    constructor(library: Web3Provider, account: string) {
        this.signer = library.getSigner(account).connectUnchecked()
        this.contract = new Contract(MARKET_GENERATION_ADDRESS, marketGenerationAbi, this.signer);
    }

    public async contribute(amount: string, referral?: string) {
        return await this.contract.contribute(referral ?? AddressZero, { value: parseEther(amount) }) ;
    }

    public async claim() {
        return await this.contract.claim()
    }

    public async claimReferralRewards() {
        return await this.contract.claimReferralRewards()
    }

    public async isActive() {
        return await this.contract.isActive();
    }

    public async getContribution(account: string) {
        const contribution = await this.contract.contribution(account);
        return getBalanceNumber(new BigNumber(contribution.toString()));
    }

    public async getTotalContribution() {
        const contribution = await this.contract.totalContribution();
        return getDisplayBalance(new BigNumber(contribution.toString()), 2, BASE_DECIMALS)
    }
}

