import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { PrimaryCard } from "../../components/Card"
import { ButtonPrimary, Option, PendingContent } from "../../components/Button";
import useTokenBalance from "../../hooks/useTokenBalance";
import { STAKING_ADDRESS, STAKING_TICKER, VAULT_ADDRESS } from "../../constants";
import BigNumber from "bignumber.js";
import CurrencyInput from "../../components/CurrencyInput";
import { getBalanceNumber, getDisplayBalance, getFullDisplayBalance } from "../../utils/formatBalance";
import { useWeb3React } from "@web3-react/core";
import { TokenService } from "../../services/TokenService";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import TransactionCompletedModal from "../../components/TransactionCompletedModal";
import { ErrorMessage } from "../../components/ErrorMessage";
import { supportedChain } from "../../utils";
import { VaultStakingInfo } from "../../dtos/VaultStakingInfo";
import { VaultService } from "../../services/VaultService";
import ContentLoader from "react-content-loader";

const CardContent = styled.div`
    display: grid;
    grid-gap: 1.5em;
    padding-bottom: 0.5em;
    width: 26em;

 ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    width: 100%;
    font-size: 0.75em;   
 `};
`

const Title = styled.div`
    padding: 0.5em 0;
    font-size: 2em;
    letter-spacing:0.025em;
    text-align: center;
`

const ButtonsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5em;
    align-items: center;
`

const TabsWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 0.15em;
    border-radius: 0.5em;
    background-color: ${({ theme }) => theme.bg2};
`
const APY = styled.div`
    justify-self:center;
    color: ${({ theme }) => theme.primary1};
    font-size: 1.35em;
    font-weight:600;
    text-transform:uppercase;
`

const Stats = styled.div`
    color: ${({ theme }) => theme.text3};
    font-size: 0.825rem;
    display:grid;
    grid-gap: 0.5em;
    grid-template-columns: auto 1fr;
    align-items:center;
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        width: 100%;
        font-size: 0.575rem;
        grid-gap: 0.25em;
    `};
`

enum Action {
    Deposit,
    Withdraw
}

enum ActionStatus {
    None,
    Approving,
    Approved,
    Progress,
    Done
}

const APYLoader = () => (
    <ContentLoader
        width={110}
        height={20}
        speed={1}
        animate={true}
        backgroundColor="#f6f6ef"
        foregroundColor="#e8e8e3"
        backgroundOpacity={0.06}
        foregroundOpacity={0.12}
    >
        <rect x="0" y="0" width="110" height="20" />
    </ContentLoader>)

const InfoLoader = () => (
    <ContentLoader
        width={110}
        height={14}
        speed={1}
        animate={true}
        backgroundColor="#f6f6ef"
        foregroundColor="#e8e8e3"
        backgroundOpacity={0.06}
        foregroundOpacity={0.12}
    >
        <rect x="0" y="0" width="110" height="14" />
    </ContentLoader>)

export const VaultStaking = () => {
    const { account, library, chainId } = useWeb3React();
    const [action, setAction] = useState<Action>(Action.Deposit);
    const stakingBalance = useTokenBalance(STAKING_ADDRESS);
    const [balance, setBalance] = useState<BigNumber>(new BigNumber(0));
    const [value, setValue] = useState<string>("");
    const [status, setStatus] = useState<ActionStatus>(ActionStatus.None);
    const [harvestStatus, setHarvestStatus] = useState<ActionStatus>(ActionStatus.None);
    const [compoundStatus, setCompoundStatus] = useState<ActionStatus>(ActionStatus.None);
    const [transactionHash, setTransactionHash] = useState<string>("");
    const [harvestTransactionHash, setHarvestTransactionHash] = useState<string>("");
    const [compoundTransactionHash, setCompoundTransactionHash] = useState<string>("");
    const [error, setError] = useState("");
    const [completedAction, setCompletedAction] = useState("");
    const [pendingAction, setPendingAction] = useState("");
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [info, setInfo] = useState<VaultStakingInfo | undefined>(undefined);
    const [loadingInfo, setLoadingInfo] = useState<boolean>(false);

    useEffect(() => {
        setBalance(action === Action.Deposit ? stakingBalance : info ? info!.staked: new BigNumber(0))
    }, [action, info, stakingBalance])

    const getInfo = async () => {
        if (account && chainId && supportedChain(chainId!)) {
            setLoadingInfo(true);
            const vaultInfo = await new VaultService(library, account!).getInfo()
            setInfo(vaultInfo);
            if(action === Action.Withdraw) {
                setBalance(vaultInfo.staked);
            }
            setLoadingInfo(false);
        }
    }
    
    useEffect(() => {
        const getInfo = async () => {
            if (account && chainId && supportedChain(chainId!)) {
                setLoadingInfo(true);
                const vaultInfo = await new VaultService(library, account!).getInfo()
                setInfo(vaultInfo);
                if(action === Action.Withdraw) {
                    setBalance(vaultInfo.staked);
                }
                setLoadingInfo(false);
            }
        }

        getInfo();    
        const timer = setInterval(() => getInfo(), 30000)
        return () => clearInterval(timer)
    }, [library, account, chainId, action])

    useEffect(() => {
        const getIsApprove = async () => {
            const service = new TokenService(library, account!, STAKING_ADDRESS);
            const approved = await service.isApproved(VAULT_ADDRESS);
            setIsApproved(approved);
            if(approved) {
                setStatus(ActionStatus.Approved);
            }
        }
        if(account && chainId && supportedChain(chainId!)) {
            getIsApprove();
        }
    }, [library, account, chainId])

    const approve = async () => {
        try {
            setStatus(ActionStatus.Approving);
            const service = new TokenService(library, account!, STAKING_ADDRESS);
            const txResponse = await service.approve(VAULT_ADDRESS);
            if (txResponse) {
                const receipt = await txResponse.wait()
                if (receipt?.status === 1) {
                    setTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Transaction Failed")
                }
            }
            setStatus(ActionStatus.Approved);
            setIsApproved(true);
        }
        catch (e) {
            console.log(e);
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            setStatus(ActionStatus.None);
        }
    }

    const deposit = async () => {
        const amount = parseFloat(value);
        if (Number.isNaN(amount) || amount <= 0) {
            setError("Enter amount");
            return;
        }
        setError("");

        try {
            setCompletedAction(`${value} ${STAKING_TICKER} ${action === Action.Deposit ? "deposited" : "withdrawn"}`);
            setPendingAction(`${action === Action.Deposit ? "Depositing" : "Withdrawing"}...`);
            setStatus(ActionStatus.Progress);

            const service = new VaultService(library, account!);
            const txResponse = action === Action.Deposit 
                ? await service.deposit(value) 
                : await service.withdraw(value)

            if (txResponse) {
                const receipt = await txResponse.wait()
                if (receipt?.status === 1) {
                    setTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Transaction Failed")
                }
            }
            setStatus(ActionStatus.Done);
            setValue("");
            await getInfo();
        }
        catch (e) {
            console.log(e)
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            setStatus(ActionStatus.None)
        }
    }

    const harvest = async () => {
        try {
            setHarvestStatus(ActionStatus.Progress);
            const txResponse = await new VaultService(library, account!).harvest(); 

            if (txResponse) {
                const receipt = await txResponse.wait()
                if (receipt?.status === 1) {
                    setHarvestTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Transaction Failed")
                }
            }
            setHarvestStatus(ActionStatus.Done);
            setValue("");
            await getInfo();
        }
        catch (e) {
            console.log(e)
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            setHarvestStatus(ActionStatus.None)
        }
    }

    const compound = async () => {
        try {
            setCompoundStatus(ActionStatus.Progress);
            const txResponse = await new VaultService(library, account!).compound(); 

            if (txResponse) {
                const receipt = await txResponse.wait()
                if (receipt?.status === 1) {
                    setCompoundTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Transaction Failed")
                }
            }
            setCompoundStatus(ActionStatus.Done);
            setValue("");
            await getInfo();
        }
        catch (e) {
            console.log(e)
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            setCompoundStatus(ActionStatus.None)
        }
    }

    return (
    <>
        <TransactionCompletedModal title={completedAction} hash={transactionHash} isOpen={status === ActionStatus.Done} onDismiss={() => setStatus(ActionStatus.None)} />
        <TransactionCompletedModal title={`${info?.rewards} ${STAKING_TICKER} harvested`} hash={harvestTransactionHash} isOpen={harvestStatus === ActionStatus.Done} onDismiss={() => setHarvestStatus(ActionStatus.None)} />
        <TransactionCompletedModal title={"Rewards compounded"} hash={compoundTransactionHash} isOpen={compoundStatus === ActionStatus.Done} onDismiss={() => setCompoundStatus(ActionStatus.None)} />
        <PrimaryCard width="auto">            
            <CardContent>
                <Title>Vault Staking</Title>
                    {account 
                        ? <>
                            <TabsWrapper>
                                <Option padding="1em" onClick={() => setAction(Action.Deposit)} active={action === Action.Deposit}>Deposit {STAKING_TICKER}</Option>
                                <Option padding="1em" onClick={() => setAction(Action.Withdraw)} active={action === Action.Withdraw}>Withdraw {STAKING_TICKER}</Option>
                            </TabsWrapper>
                            <APY>{loadingInfo ? <APYLoader/> : `${info?.apy}% APY`} </APY>              
                            <CurrencyInput
                                value={value}
                                balance={getDisplayBalance(balance, 2)}
                                numericBalance={getBalanceNumber(balance)}
                                onSubmit={deposit}
                                ticker={STAKING_TICKER}
                                label={`Amount to ${action === Action.Deposit ? "deposit" : "withdraw"}`}
                                onMax={() => setValue(getFullDisplayBalance(balance))}
                                showMaxButton={true}
                                onUserInput={setValue}
                                id={"stakingInput"}
                            />
                            {action === Action.Withdraw || isApproved
                                ? 
                                    <ButtonPrimary disabled={status === ActionStatus.Progress || !supportedChain(chainId)} onClick={deposit}>
                                        {status === ActionStatus.Progress 
                                        ? <PendingContent text={pendingAction}/> 
                                        : `${action === Action.Deposit ? "Deposit" : "Withdraw"}`}
                                    </ButtonPrimary>
                                :
                                    <ButtonsWrapper>
                                        <ButtonPrimary onClick={approve} disabled={status === ActionStatus.Approving || !supportedChain(chainId)}>
                                            {status === ActionStatus.Approving 
                                                ? <PendingContent text={"Approving..."}/>
                                                : status === ActionStatus.Approved ? "Approved" : "Approve"
                                            }
                                        </ButtonPrimary>
                                        <ButtonPrimary disabled={status !== ActionStatus.Approved || !supportedChain(chainId)} onClick={deposit}>
                                            {status === ActionStatus.Progress
                                                ? <PendingContent text={"Depositing..."}/>
                                                : "Deposit"
                                            }
                                        </ButtonPrimary>
                                    </ButtonsWrapper>
                            }
                             <ButtonsWrapper>
                                <ButtonPrimary disabled={compoundStatus === ActionStatus.Progress || !supportedChain(chainId)} onClick={compound}>
                                    {compoundStatus === ActionStatus.Approving 
                                        ? <PendingContent text={"Compounding"}/>
                                        : "Compound" 
                                    }
                                </ButtonPrimary>
                                <ButtonPrimary disabled={harvestStatus === ActionStatus.Progress || !supportedChain(chainId)} onClick={harvest}>
                                    {harvestStatus === ActionStatus.Progress
                                        ? <PendingContent text={"Harvesting..."}/>
                                        : "Harvest"
                                    }
                                </ButtonPrimary>
                            </ButtonsWrapper>
                            {error ? <ErrorMessage error={error} /> : null}
                            <Stats>
                                <span>Staked</span><span>{loadingInfo ? <InfoLoader/> : info ? `${getDisplayBalance(info!.staked)} ${STAKING_TICKER}` : ""}</span>
                                <span>Rewards</span><span>{loadingInfo ? <InfoLoader/> : `${info?.rewards.toFixed(4)} ${STAKING_TICKER}`}</span>
                            </Stats>
                        </>
                        : null}
                </CardContent>
            </PrimaryCard>
    </>
   )
}