import React, { useEffect, useState } from "react";
import { ButtonPrimary, PendingContent } from "../../components/Button";
import { useWeb3React } from "@web3-react/core";
import { MarketGenerationService } from "../../services/MarketGenerationService";
import { ErrorMessage } from "../../components/ErrorMessage";
import { extractErrorMessage } from "../../utils/extractErrorMessage";
import TransactionCompletedModal from "../../components/TransactionCompletedModal";
import { GroupMarketGenerationService } from "../../services/GroupMarketGenerationService";

enum ClaimStatus {
    Unknown,
    Claiming,
    Claimed,
    Error
}

export const Claim = ({onClaim} : {onClaim: () => void}) => {
    const { account, library, chainId } = useWeb3React();
    const [claimStatus, setClaimStatus] = useState<ClaimStatus>(ClaimStatus.Unknown);
    const [bscClaimStatus, setBscClaimStatus] = useState<ClaimStatus>(ClaimStatus.Unknown);
    const [groupClaimStatus, setGroupClaimStatus] = useState<ClaimStatus>(ClaimStatus.Unknown);
    const [claimReferralRewardsStatus, setClaimReferralRewardsStatus] = useState<ClaimStatus>(ClaimStatus.Unknown);
    const [error, setError] = useState("");
    const [claimTransactionHash, setClaimTransactionHash] = useState<string>("");
    const [bscClaimTransactionHash, setBscClaimTransactionHash] = useState<string>("");
    const [claimReferralRewardsTransactionHash, setClaimReferralRewardsTransactionHash] = useState<string>("");
    const [groupClaimTransactionHash, setGroupClaimTransactionHash] = useState<string>("");
    const [bscClaimAvailable, setBscClaimAvailable] = useState<boolean>(false);

    const getClaimStatus = async () => {
        if (library && account && chainId) {
            const groupService = new GroupMarketGenerationService(library, account!);
            const claim = await groupService.getClaim();
            setBscClaimAvailable(claim.remaining > 0);
        }
    }

    useEffect(() => {
        getClaimStatus()
        const timer = setInterval(() => getClaimStatus(), 30000)
        return () => clearInterval(timer)
    }, [library, account, chainId])

    const claim = async () => {
        try {
            setClaimStatus(ClaimStatus.Claiming);
            const service = new MarketGenerationService(library, account!);
            const txResponse = await service.claim();

            if (txResponse) {
                const receipt = await txResponse.wait();
                if (receipt?.status === 1) {
                    setClaimTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Claim Failed");
                }
            }
            setClaimStatus(ClaimStatus.Claimed);
            onClaim();
        }
        catch (e) {
            console.log(e);
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            
            setClaimStatus(ClaimStatus.Error);
        }
    }

    const bscClaim = async () => {
        try {
            setBscClaimStatus(ClaimStatus.Claiming);
            const service = new GroupMarketGenerationService(library, account!);
            const txResponse = await service.claim();

            if (txResponse) {
                const receipt = await txResponse.wait();
                if (receipt?.status === 1) {
                    setBscClaimTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Claim Failed");
                }
            }
            setBscClaimStatus(ClaimStatus.Claimed);
            onClaim();
        }
        catch (e) {
            console.log(e);
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            
            setBscClaimStatus(ClaimStatus.Error);
        }
    }

    const claimReferralRewards = async () => {
        try {
            setClaimReferralRewardsStatus(ClaimStatus.Claiming);
            const service = new MarketGenerationService(library, account!);
            const txResponse = await service.claimReferralRewards();

            if (txResponse) {
                const receipt = await txResponse.wait();
                if (receipt?.status === 1) {
                    setClaimReferralRewardsTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Referral Rewards Claim Failed");
                }
            }
            setClaimReferralRewardsStatus(ClaimStatus.Claimed);
            onClaim();
            await getClaimStatus();
        }
        catch (e) {
            console.log(e);
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            setClaimReferralRewardsStatus(ClaimStatus.Error);
        }
    }

    const claimForGroup = async () => {
        try {
            setGroupClaimStatus(ClaimStatus.Claiming);
            const service = new GroupMarketGenerationService(library, account!);
            const txResponse = await service.groupClaim();

            if (txResponse) {
                const receipt = await txResponse.wait();
                if (receipt?.status === 1) {
                    setGroupClaimTransactionHash(receipt.transactionHash);
                }
                else {
                    setError("Claim for Group Failed");
                }
            }
            setGroupClaimStatus(ClaimStatus.Claimed);
            onClaim();
            await getClaimStatus();
        }
        catch (e) {
            console.log(e);
            const errorMessage = extractErrorMessage(e);
            if(errorMessage) {
                setError(errorMessage);
            }
            setGroupClaimStatus(ClaimStatus.Error);
        }
    }

    return (
        <>
            <TransactionCompletedModal title={"Claimed"} 
                hash={claimTransactionHash} 
                isOpen={claimStatus === ClaimStatus.Claimed} 
                onDismiss={() => setClaimStatus(ClaimStatus.Unknown)} />

            <TransactionCompletedModal title={"Claimed"} 
                hash={bscClaimTransactionHash} 
                isOpen={bscClaimStatus === ClaimStatus.Claimed} 
                onDismiss={() => setBscClaimStatus(ClaimStatus.Unknown)} />

            <TransactionCompletedModal title={"Referral Rewards Claimed"} 
                hash={claimReferralRewardsTransactionHash} 
                isOpen={claimReferralRewardsStatus === ClaimStatus.Claimed} 
                onDismiss={() => setClaimReferralRewardsStatus(ClaimStatus.Unknown)} />

            <TransactionCompletedModal title={"Claimed for Group"} 
                hash={groupClaimTransactionHash} 
                isOpen={groupClaimStatus === ClaimStatus.Claimed} 
                onDismiss={() => setGroupClaimStatus(ClaimStatus.Unknown)} />

            <ButtonPrimary disabled={claimStatus === ClaimStatus.Claiming} onClick={claim}>
                {claimStatus === ClaimStatus.Claiming
                    ? <PendingContent text={"Claiming..."}/>
                    : "Claim"
                }
            </ButtonPrimary>

            <ButtonPrimary disabled={bscClaimStatus === ClaimStatus.Claiming || !bscClaimAvailable} onClick={bscClaim}>
                {bscClaimStatus === ClaimStatus.Claiming
                    ? <PendingContent text={"Claiming..."}/>
                    : "Claim if contributed on BSC"
                }
            </ButtonPrimary>

            <ButtonPrimary disabled={claimReferralRewardsStatus === ClaimStatus.Claiming} onClick={claimReferralRewards}>
                {claimReferralRewardsStatus === ClaimStatus.Claiming
                    ? <PendingContent text={"Claiming Referral Rewards..."}/>
                    : "Claim Referral Rewards"
                }
            </ButtonPrimary>

            <ButtonPrimary disabled={groupClaimStatus === ClaimStatus.Claiming} onClick={claimForGroup}>
                {groupClaimStatus === ClaimStatus.Claiming
                    ? <PendingContent text={"Claiming for Group..."}/>
                    : "Claim for Group if contributed on BSC"
                }
            </ButtonPrimary>
            {error ? <ErrorMessage error={error} /> : null}
        </>
    );
};
